import React from "react";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { NumericFormat } from "react-number-format";
import _ from "lodash";

//Custom UI Components
import { PeriodDropdown } from "../../CustomConfiguration/CustomConfigurationConstants";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";

//Constants
import {
  translateTypeConstantToString,
  CONFIGURATION_DATA_TYPE,
  CONFIGURATION_TYPE,
  TYPE,
  DAYS_ARREARS,
  CONFIGURATION_SINGLE_KEY_TYPE,
} from "../../../constants/appConstants";
import { DATA_TYPE } from "./Constants";

//Strings
import { Strings } from "../../../resources/i18n/i18n";

//Assets
import addSubType from "../../../resources/images/ic_add_subtype_bold.svg";

//Utility
import Utility from "../../../utils/Utility";

//Styles
import "./EditableDataTable.scss";
class EditableDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  static getDerivedStateFromProps(props) {
    let data = { ...props.data };
    return !props.resetData ? { data } : null;

  }

  onDataChange = (event, row, column, dataType) => {
    //NOSONAR
    let data = { ...this.state.data };
    let value = event.target.value;
    if (dataType === DATA_TYPE.FLOAT) {
      value = Utility.fixedDecimalPoint(event.target.value, 2);
    } else {
      if (dataType === DATA_TYPE.INTEGER) {
        value = value !== "" ? parseInt(value) : 0;
      }
      if (dataType === DATA_TYPE.HOURS) {
        value = !isNaN(value) && value > 12 ? value % 12 : value;
      } else if (dataType === DATA_TYPE.MINUTES) {
        value = !isNaN(value) && value >= 60 ? value % 60 : value;
      } else if (dataType === DATA_TYPE.BOOLEAN) {
        value = event.target.checked === true ? 1 : 0;
      }
    }

    data.dataRows[row] = {
      ...data.dataRows[row],
      [column]: value,
    };
    this.setState({
      data,
    });
    this.props.onRowDataChange(
      data.dataRows,
      this.props.index !== undefined ? this.props.index : false
    );
  };

  onTermsChange = (row, column, option) => {
    let data = { ...this.state.data };
    const terms = option.map((term) => parseInt(term));
    terms.sort((a, b) => {
      return a - b;
    });

    data.dataRows[row] = {
      ...data.dataRows[row],
      [column]: terms,
    };

    this.setState({
      data,
    });
    this.props.onRowDataChange(
      data.dataRows,
      this.props.index !== undefined ? this.props.index : false
    );
  };

  onInputValueChange = (event, row, column, dataType, dataRow) => {
    //NOSONAR
    let data = { ...this.state.data };
    let value = event.target.value;

    if (dataType === DATA_TYPE.FLOAT) {
      value = Utility.fixedDecimalPoint(event.target.value, 2);
    } else {
      if (
        dataType === DATA_TYPE.INTEGER ||
        dataType === DATA_TYPE.AMOUNT ||
        (dataType === DATA_TYPE.NUMBER &&
          dataRow.default !== Strings("Interestrate"))
      ) {
        if (value !== "") {
          value = value !== "Other" ? parseInt(value) : "-1";
        }
      }
      if (
        dataRow.default !== Strings("Interestrate") &&
        column === "requested" &&
        dataType !== DATA_TYPE.SELECT
      ) {
        value = value !== "" ? parseInt(value) : 0;
      }
      if (dataType === DATA_TYPE.HOURS) {
        value = !isNaN(value) && value > 12 ? value % 12 : value;
      } else if (dataType === DATA_TYPE.MINUTES) {
        value = !isNaN(value) && value >= 60 ? value % 60 : value;
      }
    }
    if (this.props.type !== CONFIGURATION_TYPE.LOAN_STATUS) {
      data.dataRows[row] = {
        ...data.dataRows[row],
        [column]: value,
      };
    } else {
      dataRow.daysArrears = {
        ...dataRow.daysArrears,
        [column]: value,
      };
    }
    this.setState({
      data,
    });
    this.props.onRowDataChange(
      data.dataRows,
      this.props.index !== undefined ? this.props.index : false
    );
  };

  handleAddRow = () => {
    let data = { ...this.state.data };
    let newRow = { ...data.DEFAULT_ROW };
    if (this.props.type === CONFIGURATION_TYPE.PROVISION
      || this.props.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA) {
      data.dataRows.splice(data.dataRows.length - 1, 0, newRow);
    } else {
      data.dataRows = [...data.dataRows, newRow];
    }
    this.setState({
      data,
    });
    this.props.onRowDataChange(
      data.dataRows,
      this.props.index !== undefined ? this.props.index : false
    );
  };

  handleDeleteRow = (deleteRowIndex) => {
    let data = { ...this.state.data };
    let dataRows = [...data.dataRows];
    dataRows.splice(deleteRowIndex, 1);
    data.dataRows = dataRows;
    this.setState({ data });
    this.props.onRowDataChange(
      data.dataRows,
      this.props.index !== undefined ? this.props.index : false
    );
  };

  renderNumberFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        type='text'
        decimalScale='2'
        isNumericString
      // allowNegative={false}
      />
    );
  });

  renderTableDataType = (column, dataRow, rowIndex) => {

    //NOSONAR
    if (column.key === CONFIGURATION_DATA_TYPE.PERIOD) {
      return (
        <Select
          className='w-100'
          value={dataRow[column.key]}
          onChange={(e) => {
            this.onDataChange(
              e,
              rowIndex,
              column.key,
              column.dataType
            );
          }}
          disabled={this.props.readOnly || !column.editable}
        >
          {PeriodDropdown.map((period) => {
            return (
              <MenuItem key={period.key} value={period.value}>
                {period.label}
              </MenuItem>
            );
          })}
        </Select>
      );
    } else if (column.key === "terms") {
      return (
        <Stack
          direction='row'
          spacing={1}
          className='d-block text-start'
        >
          {this.props.readOnly ? (
            dataRow.terms &&
            dataRow.terms.map(
              (term, index) =>
                term !== null && (
                  <Chip
                    key={index}
                    className='ms-0 me-2 mt-1'
                    label={term}
                  />
                )
            )
          ) : (
            <Autocomplete
              multiple
              id='tags-filled'
              options={[]}
              style={{ width: 400 }}
              defaultValue={dataRow.terms}
              freeSolo
              disableClearable
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    option !== null && (
                      <Chip
                        {...getTagProps({ index })}
                        label={option}
                      />
                    )
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  type='number'
                  {...params}
                  variant='standard'
                />
              )}
              onChange={(_event, option, _reason) => {
                //_event and _reason are to remove sonalint warning
                this.onTermsChange(rowIndex, column.key, option);
              }}
            />
          )}
        </Stack>
      );
    } else if (
      column.key === "loanStatus" ||
      (column.key === TYPE && column.editable === true)
    ) {
      let value;
      value = dataRow[column.key];

      return this.props.readOnly || !column.editable ? (
        value
      ) : (
        <TextField
          inputProps={{ style: { textAlign: "center" } }}
          placeholder={0}
          value={value}
          onChange={(e) =>
            this.onInputValueChange(
              e,
              rowIndex,
              column.key,
              column.dataType,
              dataRow
            )
          }
          variant='standard'
        />
      );
    }
     else {
      let value;
      if (column.key === TYPE && column.editable === false) {
        value = translateTypeConstantToString(dataRow[column.key]);
      } else if (
        column.dataType === DATA_TYPE.AMOUNT
      ) {
        value = dataRow[column.key] !== -1
          ? Utility.getCurrencyRepresentationOfAmount(dataRow[column.key])
          : "-";

      } else if (this.props.type === CONFIGURATION_TYPE.LOAN_STATUS) {
        if (
          column.key === DAYS_ARREARS.LOWER ||
          column.key === DAYS_ARREARS.UPPER
        ) {
          value = dataRow.daysArrears[column.key];
        } else if (
          column.key === CONFIGURATION_SINGLE_KEY_TYPE.STATUS
        ) {
          value = Strings(dataRow[column.key]).toUpperCase();
        }
      } else {
        value = Strings(dataRow[column.key]);
      }

      return this.props.readOnly || this.props?.tableForViewOnly ||
        !column.editable ||
        value === "Other" ||
        value === "-" ? (
        value
      ) : (
        <div
          className={`textBox input ${dataRow.dataType === "select" ? "px-5" : ""
            }`}
        >
          {dataRow.dataType === "select" ? (
            <TextFieldComponent
              type='select'
              handleChange={(event) =>
                this.onInputValueChange(
                  event,
                  rowIndex,
                  column.key,
                  dataRow.dataType,
                  dataRow
                )
              }
            >
              <option default disabled value=''>
                {Strings(dataRow.default)}
              </option>
              {dataRow.dropdownList.map((element) => {
                return (
                  <option
                    key={_.isObject(element) ? element.value : element}
                    value={_.isObject(element) ? element.value : element}
                  >
                    {_.isObject(element) ? Strings(element.label) : Strings(element)}
                  </option>
                );
              })}
            </TextFieldComponent>
          ) : (
            <TextField
              placeholder={"0"}
              value={value}
              onChange={(event) =>
                this.onInputValueChange(
                  event,
                  rowIndex,
                  column.key,
                  column.dataType,
                  dataRow
                )
              }
              name='numberformat'
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: this.renderNumberFormatCustom,
              }}
              variant='standard'
            />
          )}
        </div>
      );
    }
  };

  renderDeleteRowIcon = (rowIndex) => {
    return (
      <TableCell className='p-0 align-items-center cursorPointer'>
        <i
          className='ps-2 fa fa-trash'
          aria-hidden='true'
          title='Eliminar Subtipo'
          onClick={() => {
            this.handleDeleteRow(rowIndex);
          }}
        />
      </TableCell>
    );
  };

  renderDeleteIcon = (rowIndex) => {
    if (!this.props.readOnly && this.props.rowDelete === true) {
      if (this.props.type === CONFIGURATION_TYPE.EMI) {
        return this.state.data.dataRows.length - 1 === rowIndex
          && this.state.data.dataRows.length > 1
          && this.renderDeleteRowIcon(rowIndex);
      } if (this.props.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA) {
        return this.state.data.dataRows.length > 1
          && rowIndex === 0
          && this.renderDeleteRowIcon(rowIndex)
      } else {
        return this.state.data.dataRows.length > 1
          && !this.props?.tableForViewOnly && this.renderDeleteRowIcon(rowIndex)
      }
    }
  }

  renderChangeRateTable = () => {

    const StyledTableCell = this.stylesTableCell();
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              {this.state.data &&
                this.state.data.columns &&
                this.state.data.columns.length > 0 &&
                this.state.data.columns.map((column, index) => (
                  <StyledTableCell key={column.key + index}>
                    {column.title}
                  </StyledTableCell>
                ))}
              {!this.props.readOnly && (
                <StyledTableCell></StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data &&
              this.state.data.dataRows &&
              this.state.data.dataRows.map((row, rowIndex) => (
                <TableRow key={Object.keys(row)[0] + rowIndex}>
                  {this.state.data.columns.map((column, colIndex) => (
                    <TableCell
                      key={column.key + rowIndex + colIndex}
                      component='th'
                      scope='row'
                      title={
                        column.key === "terms"
                          ? Strings("durationTitle")
                          : row[column.key]
                      }
                    >
                      {this.renderTableDataType(
                        column,
                        row,
                        rowIndex
                      )}
                    </TableCell>
                  ))}

                  {this.renderDeleteIcon(rowIndex)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  stylesTableCell = () => {
    return styled(TableCell)(({ theme }) => {
      return {
        [`&.${tableCellClasses.head}`]: {
          fontWeight: "bold",
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      };
    });
  };

  renderLoanStatusTable = () => {
    const StyledTableCell = this.stylesTableCell();
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              {this.state.data &&
                this.state.data.columns &&
                this.state.data.columns.length > 0 &&
                this.state.data.columns.map(
                  (
                    column,
                    index //NOSONAR
                  ) => (
                    <StyledTableCell key={column.key + index}>
                      {column.title}
                    </StyledTableCell>
                  )
                )}
              {!this.props.readOnly && (
                <StyledTableCell></StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data &&
              this.state.data.dataRows &&
              this.state.data.dataRows.states.map((row, rowIndex) => (
                <TableRow key={Object.keys(row)[0] + rowIndex}>
                  {this.state.data.columns.map((column, colIndex) => (
                    <TableCell
                      key={column.key + rowIndex + colIndex}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                    >
                      {this.renderTableDataType(
                        column,
                        row,
                        rowIndex
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    return (
      <>
        <Row className={"editableDataTable justify-content-center w-auto"}>
          <Row className="w-auto">
            <Col md={12}>
              {this.props.type !==
                CONFIGURATION_TYPE.LOAN_STATUS && (
                  <Row className='noPadding'>
                    <Col
                      md={12}
                      className='noPadding d-flex justify-content-center'
                    >
                      {this.renderChangeRateTable()}
                    </Col>
                  </Row>
                )}
              {this.props.type === CONFIGURATION_TYPE.LOAN_STATUS &&
                this.renderLoanStatusTable()}

              {!this.props.readOnly && this.props.rowAdd === true && !this.props.tableForViewOnly && (
                <Row>
                  <Col
                    md={12}
                    className='actionButtonPlaceHolder mt-2'
                  >
                    <span onClick={this.handleAddRow}>
                      <i>
                        <img
                          className='actionIcon'
                          src={addSubType}
                          alt='add record'
                        />
                      </i>
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
            <hr className="opacity-100"/>
          </Row>
        </Row>
      </>
    );
  }
}

export default EditableDataTable;
