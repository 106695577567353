import React from "react";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

export const TABLE_COLUMNS_CONST = {
  INDEX: "index",
  DUE_DATE: "dueDate",
  AMOUNT_TO_PAY: "amountToPay",
  AMOUNT_PAID: "amountPaid",
  STATUS: "status",
  PAYMENT_DATE: "pds",
  RECEIPT: "receipt",
  REASON: "reason",
  NOTE: "noteId",
  TRANSFER_RECEIPTS: "transferReceipts",
  PAYMENT_MODES: "paymentModes",
  PAYMENT_SOURCE: "paymentSources",
};
export const TABLE_COLUMNS = [
  { key: "index", name: "index", minWidth: 60 },
  { key: "dueDate", name: "dueDate", minWidth: 80 },
  { key: "amountToPay", name: "amountToPay", minWidth: 80 },
  { key: "amountPaid", name: "amountPaid", minWidth: 80 },
  { key: "status", name: "status", minWidth: 100 },
  { key: "receipt", name: "receipt", minWidth: 100 },
  { key: "pdb", name: "pdb", minWidth: 80 },
  { key: "action", name: "action" },
];
export const TABLE_SECOND_COLUMNS = [
  { key: "pds", name: "pds", minWidth: 80 },
  { key: "reason", name: "reason", minWidth: 100 },
  { key: "noteId", name: "noteId", minWidth: 100 },
  { key: "transferReceipts", name: "transferReceipts", minWidth: 80 },
  { key: "paymentModes", name: "paymentModes", minWidth: 100 },
  { key: "paymentSources", name: "paymentSources", minWidth: 100 },
];

export const ASSOCIATION = [
  { key: "sp", label: "SALES_PERSON" },
  { key: "msg", label: "MESSENGER", subKey: "msgty" },
  { key: "co", label: "COLLECTIONS_OFFICER" },
  { key: "mm", label: "MESSENGER_MODERATOR" },
  { key: "cm", label: "COLLECTIONS_SUPERVISOR" },
  { key: "sm", label: "SALES_MODERATOR" },
  { key: "aa", label: "ANALYST" },
  { key: "oo", label: "OPERATIONS_OFFICER" },
  { key: "ao", label: "ACCOUNTING_OFFICER" },
];
export const ASSOCIATION_FACTORIAL = [
  { key: "sp", label: "SALES_PERSON" },
  { key: "msg", label: "MESSENGER", subKey: "msgty" },
  { key: "co", label: "COLLECTIONS_OFFICER" },
  { key: "mm", label: "MessengerModerator" },
  { key: "cm", label: "COLLECTIONS_SUPERVISOR" },
  { key: "sm", label: "SALES_MODERATOR" },
  { key: "aa", label: "ANALYST" },
  { key: "oo", label: "OPERATIONS_OFFICER" },
];

export const EXT_FACT_SUPPLIER_DETAILS = [
  { key: "rnc", label: "rnc" },
  { key: "bunm", label: "businessName" },
  { key: "banm", label: "bankName" },
  { key: "act", label: "accountType" },
  { key: "acn", label: "accountNumber" },
]

export const FAST_CASH_BANK_DETAILS = [
  { key: "banm", label: "bankName" },
  { key: "act", label: "accountType" },
  { key: "acn", label: "accountNumber" },
]

export const ASSIGN_REPAYMENT_CONST = {
  REPAYMENT_PLAN: "REPAYMENT_PLAN",
  EMI: "EMI",
};

export const DEFAULT_DATA_FOR_ASSIGN_REPAYMENT = {
  term: 0,
  interest: 0,
  lateFees: 0,
  rate: 0,
};

export const DEFAULT_DATA_FOR_ASSIGN_REPAYMENT_EMI = {
  emiAmount: 0,
  totalBalanceAMount: 0,
};

export const NEW_REPAYMENT_PLAN_TABLE_COLUMNS = ["default", "existing", "requested"];


export const FREQUENCIES = {
  WEEKLY_SIX: "WEEKLY_SIX",
  WEEKLY_THRICE: "WEEKLY_THRICE",
  WEEKLY_TWICE: "WEEKLY_TWICE",
  MONTHLY_TWICE: "MONTHLY_TWICE",
  MONTHLY_ONCE: "MONTHLY_ONCE",
  WEEKLY_ONCE: "WEEKLY_ONCE",
};

export const FREQUENCIES_WITH_INDEXES = [
  FREQUENCIES.MONTHLY_ONCE,
  FREQUENCIES.MONTHLY_TWICE,
]

export const WEEKLY_SIX = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]

export const LOAN_AMOUNT_COLUMNS = [
  { key: "dt", name: "Payment Date" },
  { key: "c", name: "Capital Paid" },
  { key: "i", name: "Interest Paid" },
  { key: "lf", name: "Arrears Paid" },
  { key: "ttl", name: "Arrears Paid" },
];
export const LOAN_AMOUNT_PAY_COLUMNS = [
  { key: "ix", name: "Payment Date" },
  { key: "c", name: "Capital Paid" },
  { key: "i", name: "Interest Paid" },
  { key: "lf", name: "Arrears Paid" },
  { key: "ttl", name: "Arrears Paid" },
];
export const LOAN_DETAILS_REPORT_DOWNLOAD = {
  downloadUrl: "/amortizationsV2/download?fileType=PDF",
  keysArray: ["loanId", "applicationId"],
};
export const LOAN_STATE_CONST = {
  CLOSED: "CLOSED",
  INACTIVE: "INACTIVE",
};

export const LOAN_VIEW_PREVIOUS_REQ_CONST = {
  TAKE_OFF_LATE_FEES: "TAKE_OFF_LATE_FEES",
  NEW_REPAYMENT_PLAN: "NEW_REPAYMENT_PLAN",
};

export const SUMMARY_TABLE_COLUMNS_CONST = {
  NEXT_DUE_DATE: "ndt",
  UNPAID_CAPITAL: "uc",
  UNPAID_INTEREST: "ui",
  UNPAID_LATE_FEES: "ulf",
  AMOUNT_TO_COLLECT: "atc",
};

export const SUMMARY_TABLE_COLUMNS = [
  { name: "ndt", key: "ndt", minWidth: 100 },
  { name: "atc", key: "atc", minWidth: 100 },
  { name: "uc", key: "uc", minWidth: 100 },
  { name: "ui", key: "ui", minWidth: 100 },
  { name: "ulf", key: "ulf", minWidth: 100 },
];

export const LOAN_DETAILS_INFO_ACCESS = [
  "ADMIN",
  "GENERAL_MANAGER",
  "COLLECTIONS_SUPERVISOR",
  "OPERATIONS_MANAGER",
  "COLLECTIONS_MANAGER",
];
export const ALLOWED_FREQUENCY_NO_ACCESS = [
  "LOAN_PORTFOLIO_MANAGER"
]
export const MINIMART_DETAILS_INFO_ACCESS = [
  "COLLECTIONS_OFFICER",
  "ADMIN",
  "GENERAL_MANAGER",
  "COLLECTIONS_SUPERVISOR",
  "OPERATIONS_MANAGER",
  "COLLECTIONS_MANAGER",

];

const renderFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className}`}>
        {data[info.key] ? Strings(data[info.key]) : Strings("NA")}
      </span>
    </>
  );
};

const renderArrayFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className} `}>
        {data[info.key] ? data[info.key].join(", ") : Strings("NA")}
      </span>
    </>
  );
};

const renderBenefit = (_info, data) => {
  if (data.prbty === "DISCOUNT") {
    return renderCurrencyFormatter(
      { key: "prdis", label: Strings("discount") + " ($) " },
      data
    );
  } else if (data.prbty === "INSTALLMENT") {
    return renderArrayFormatter(
      { key: "prttrms", label: Strings("installmentIds") },
      data
    );
  }
  return null;
};

const renderCurrencyFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className} `}>
        {data[info.key]
          ? Utility.getCurrencyRepresentationOfAmount(data[info.key])
          : Strings("NA")}
      </span>
    </>
  );
};

export const PROMOTION_DETAILS_KEYS = [
  {
    key: "prt",
    label: "promotionName",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "prbty",
    label: "benefitType",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "benefitTypeKey",
    label: "",
    className: "",
    formmater: renderBenefit,
  },
];

export const CEDULAHISTORY_COLUMNS = [
  { key: "rl", name: "Role" },
  { key: "oci", name: "oldCedulaNumber" },
  { key: "nci", name: "newCedulaNumber" },
  { key: "fp", name: "PhotoID_FRONT" },
  { key: "bp", name: "PHOTOID_BACK" },
  { key: "st", name: "Status" },
  { key: "aon", name: "approvalDate" },
  { key: "aby", name: "approvedBy" },
];

export const AMORTIZATION_ADD_NOTE_ACCESS = [
  "COLLECTIONS_OFFICER",
  "COLLECTIONS_SUPERVISOR",
  "COLLECTIONS_MANAGER",
]