import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import SignupRequestDetails from "./SignupRequestDetails";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getSignUpMinimartRequestAction,
  saveSignUpRequestAction,
  stopAssignmentAction,
} from "./Actions";
import {
  getNoteAction,
  AddNoteAction,
} from "../AnalystApproval/AnalystApprovalAction";
import { assignSalesPersonAction } from "../MinimartDetails/MiniMartDetailsAction";
import { setSearchFilterAction } from "../../constants/Action";
import { sendUpdatedMinimartDetailsAction, editScalaMinimartRequest } from "../LoanDetails/LoanDetailsAction";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  SEARCH_FILTER_PAGES,
  SIGNUP_REQUEST_FILTER,
  CI_MINIMART_FILTER,
} from "../../constants/appConstants";
import { STATUS_LIST } from "./Constants";
import { getAssignedMinimartUserListAction } from "../MinimartApproval/MinimartApprovalAction";

// Strings
import { Strings } from "../../resources/i18n/i18n";
import Utility from "../../utils/Utility";

class SignupRequestDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    /**
     * Below function call is used for restricting multiple API calls.
     * Passed API call and time in miliseconds to debounce function as arguments which ensures that the API call is made only once within a specified time frame, even if the button is clicked multiple times.
     */
    this.debounceEditScalaMinimartRequest = Utility.debounce(this.callEditScalaMinimartRequest, 10000);
    this.state = {
      showLoader: false,
      minimart: null,
      emInfo: null,
      blacklisted: false,
      isExistingMinimartCode: false,
      minimartList: [],
      toShowToast: false,
      message: "",
      level: "",
      errorMessage: "",
      note: null,
      deactivated: false,
    };
  }

  componentDidMount() {
    this.getSignUpMinimartRequest();
  }

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.SIGNUP_REQUEST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SIGNUP_REQUEST,
        {
          ...SIGNUP_REQUEST_FILTER,
        }
      );
    }
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.CI_MINIMART &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CI_MINIMART,
        {
          ...CI_MINIMART_FILTER,
        }
      );
    }
  }

  getSignUpMinimartRequest = () => {
    this.setState({
      showLoader: true,
    });
    getSignUpMinimartRequestAction(
      this.props.selectedMinimart.id,
      this.props.searchPage === SEARCH_FILTER_PAGES.SIGNUP_REQUEST,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            showLoader:
              this.props.searchPage ===
              SEARCH_FILTER_PAGES.SIGNUP_REQUEST &&
              response.data.data.info.nid !== null,
            minimart: response.data.data.info,
            minimartList: response.data.data.mmd,
            emInfo: response.data.data.eminfo,
            blacklisted: response.data.data.iob,
            isExistingMinimartCode: response.data.data.iemc,
            deactivated: response.data.data.iod,
          });
          response.data.data.info.nid &&
            this.getNote(response.data.data.info.nid);
        } else {
          this.setState({
            showLoader: false,
          });
        }
      }
    );
  };

  getNote = (noteId) => {
    this.setState({ showLoader: true });
    getNoteAction(noteId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          note: response.data.data,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  saveMinimart = (request, successCallback, errorCallback) => {
    this.setState({ showLoader: true });
    saveSignUpRequestAction(
      request,
      this.props.searchPage === SEARCH_FILTER_PAGES.SIGNUP_REQUEST,
      (response) => {
        //NOSONAR
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              showLoader: false,
              toShowToast: true,
              message:
                request.st === STATUS_LIST.REJECTED
                  ? Strings("minimartRejectedNotification")
                  : request.st === STATUS_LIST.APPROVED //NOSONAR
                    ? Strings("minimartApprovedNotification")
                    : Strings("MinimartExisting"),
              level: "success",
            },
            () => {
              if (
                request.st === STATUS_LIST.REJECTED ||
                request.st === STATUS_LIST.EXISTING
              ) {
                this.gotoSignUpRequest();
                successCallback();
              } else {
                this.props.searchPage ===
                  SEARCH_FILTER_PAGES.CI_MINIMART &&
                  this.gotoSignUpRequest();
                successCallback(
                  this.props.searchPage ===
                    SEARCH_FILTER_PAGES.SIGNUP_REQUEST
                    ? response.data.data.mid
                    : null
                );
              }
            }
          );
        } else {
          this.setState(
            {
              showLoader: false,
            },
            () => {
              errorCallback(response.error.message);
            }
          );
        }
      }
    );
  };

  gotoSignUpRequest = () => {
    setTimeout(() => {
      this.props.history.push(
        this.props.searchPage === SEARCH_FILTER_PAGES.SIGNUP_REQUEST
          ? ROUTES.MTP_SIGNUP_REQUEST
          : ROUTES.MINIMART_APPROVAL_REQUESTS
      );
    }, 2000);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  addNote = (request, successCallback, errorCallback) => {
    this.setState({ showLoader: true });
    AddNoteAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
          },
          () => successCallback(response.data.data.id)
        );
      } else {
        this.setState(
          {
            showLoader: false,
          },
          () => {
            this.scrollToTop();
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  assignSalesPerson = (request, successCallback, errorCallback) => {
    this.setState({
      showLoader: true,
      errorMessage: "",
      toShowToast: false,
    });

    assignSalesPersonAction(request, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            toShowToast: true,
            message: Strings(
              "salesPersonAssignedSuccess"
            ),
            level: "success",
            showLoader: false,
            errorMessage: "",
          },
          () => {
            this.gotoSignUpRequest();
            successCallback();
          }
        );
      } else {
        this.setState(
          {
            message: responseData.error.message,
            level: "error",
            showLoader: false,
          },
          () => {
            errorCallback(responseData.error.message);
          }
        );
      }
    });
  };

  stopAssignment = (signUpId, errorCallback) => {
    this.setState({ showLoader: true });
    stopAssignmentAction(signUpId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            toShowToast: true,
            message: Strings(
              "StopAssignmentSuccessfully"
            ),
            level: "success",
            showLoader: false,
            errorMessage: "",
          },
          () => {
            this.getSignUpMinimartRequest();
          }
        );
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            showLoader: false,
          },
          errorCallback
        );
      }
    });
  };

  getAssignedMinimartUserList = (
    requestData,
    successCallback,
    errorCallback
  ) => {
    this.setState({
      showLoadingModal: true,
    });
    getAssignedMinimartUserListAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
          },
          () => {
            successCallback(response.data.data);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
          },
          () => {
            errorCallback(response.error);
          }
        );
      }
    });
  };

  updateMinimartDetails = (request, callback) => {
    this.setState({
      showLoadingModal: true,
      isSuccess: false,
      isError: false,
    });
    request["mid"] = request.emid;
    request["otp"] = request.op;
    delete request.add;
    delete request.fn;
    delete request.ln;
    delete request.lat;
    delete request.long;
    delete request.op;
    delete request.emid;
    sendUpdatedMinimartDetailsAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            level: "success",
            toShowToast: true,
            message: Strings("DataUpdatedSuccessfully"),
          }, () => {
            this.getSignUpMinimartRequest();
            callback();
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          toShowToast: true,
          level: "error",
          message: apiResponse.error.message,
        }, () => {
          this.getSignUpMinimartRequest();
          callback();
        }
        );
      }
    });
  };
  editScalaMinimartRequest = (request, callback) => {
    this.setState({
      toShowToast: false,
      showLoadingModal: true,
      isSuccess: false,
      isError: false,
    });

    request["id"] = this.props.selectedMinimart.id;
    this.debounceEditScalaMinimartRequest(request, callback);
  };

  callEditScalaMinimartRequest = (request, callback) => {
    editScalaMinimartRequest(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            level: "success",
            toShowToast: true,
            message: Strings("DataUpdatedSuccessfully"),
          }, () => {
            callback();
            this.getSignUpMinimartRequest();
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          toShowToast: true,
          level: "error",
          message: Strings(apiResponse.error.message),
        }, () => {
          callback();
          this.getSignUpMinimartRequest();
        });
      }
    });
  };

  render() {
    return (
      <>
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.message}
            userNotificationObj={{
              message: this.state.message,
              level: this.state.level,
            }}
          />
        )}
        <SignupRequestDetails
          minimart={this.state.minimart}
          minimartList={this.state.minimartList}
          getBranchList={this.props.getBranchList}
          getZonesByBranch={this.props.getZonesByBranch}
          branchList={this.props.branches}
          zones={this.props.zones}
          mZones={this.props.mZones}
          goBack={this.goBack}
          saveMinimart={this.saveMinimart}
          addNote={this.addNote}
          gotoSignUpRequest={this.gotoSignUpRequest}
          assignSalesPerson={this.assignSalesPerson}
          stopAssignment={this.stopAssignment}
          selectedMinimart={this.props.selectedMinimart}
          getAssignedMinimartUserList={
            this.getAssignedMinimartUserList
          }
          note={this.state.note}
          isSignupRequest={
            this.props.searchPage ===
            SEARCH_FILTER_PAGES.SIGNUP_REQUEST
          }
          loggedInUser={this.props.loggedInUser}
          emInfo={this.state.emInfo}
          updateMinimartDetails={this.updateMinimartDetails}
          editScalaMinimartRequest={this.editScalaMinimartRequest}
          errorMessage={this.state.errorMessage}
          blacklisted={this.state.blacklisted}
          isExistingMinimartCode={this.state.isExistingMinimartCode}
          deactivated={this.state.deactivated}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedMinimart: state.signUpRequestReducer.selectedMinimart,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setSearchFilterAction, sendUpdatedMinimartDetailsAction, editScalaMinimartRequest },
    dispatch
  );
};
const SignupRequestDetailsWrapper = withHOC(
  SignupRequestDetailsContainer
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupRequestDetailsWrapper);
